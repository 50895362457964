import FullScreenLoader from "coredde/lib/components/FullScreenLoader";
import InternetApp from "coredde/lib/InternetApp";
import { TenantContextProvider } from "coredde/lib/services/tenantContext";
import InternetLoginRouterBG from "bg/lib/InternetLoginRouterBG";
import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import ClientAddingOtherDokuBG from "bg/lib/pages/client/ClientManageDokuBox/components/ClientAddingOtherDokuBG";
import { LogoBG } from "bg/lib/assets/LogoBG";
import { LogoMonochromaticBG } from "bg/lib/assets/LogoMonochromaticBG";

export const indexBGContent = (
	<StrictMode>
		<Suspense fallback={<FullScreenLoader />}>
			<BrowserRouter basename={process.env.REACT_APP_INTERNET_ROUTER_BASENAME ? process.env.REACT_APP_INTERNET_ROUTER_BASENAME + "BG" : ""}>
				<TenantContextProvider
					tenant={"BG"}
					tenantComponentsMap={{
						internetLoginRouter: InternetLoginRouterBG,
						clientAddingOtherDoku: ClientAddingOtherDokuBG,
						logo: LogoBG,
						logoMonochromatic: LogoMonochromaticBG,
					}}
				>
					<InternetApp skipContractSignedCheck={true}/>
				</TenantContextProvider>
			</BrowserRouter>
		</Suspense>
	</StrictMode>
);

ReactDOM.render(indexBGContent, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
